import React from 'react'
import { navigate } from '@reach/router'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { first, get } from 'lodash'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import * as Accessa from 'accessa'
import Paper from '@material-ui/core/Paper'
import Tree from 'accessa/lib/Lists/Tree'
import { useTranslation } from 'react-i18next'
import {
  DarkMode,
  Hero,
  RichText,
  withGlobals,
} from '../components'

export const hasLength = (a) =>
  Array.isArray(a) && a.length > 0

export const mapToListItem = (a = []) =>
  a.map((item) => ({
    listItemText: item,
  }))

export const renderList = (Component) => ({
  // eslint-disable-next-line
  data,
  ...rest
}) =>
  hasLength(data) ? (
    <Component {...rest} data={data} />
  ) : null

// eslint-disable-next-line
const SubPageSubtitle = ({ children }) => {
  const { t } = useTranslation('titles')
  return (
    <Typography
      variant="h3"
      className="underline"
      style={{ margin: 0 }}
    >
      {t(children)}
    </Typography>
  )
}

const SubPageProducts = renderList(({ data }) => (
  <Grid item xs={12} md={8}>
    <SubPageSubtitle>commonProducts</SubPageSubtitle>
    <Accessa.Lists.Legend lists={data} />
  </Grid>
))

const SubPageMaterials = renderList(({ data }) => {
  const lists = data.map((x) => x.name)
  return (
    <Grid item md={4} xs={12}>
      <DarkMode>
        <Paper elevation={0}>
          <Box p={3}>
            <SubPageSubtitle>
              commonMaterials
            </SubPageSubtitle>
            <Box mt={2}>
              <Accessa.Lists.Archer
                lists={mapToListItem(lists)}
              />
            </Box>
          </Box>
        </Paper>
      </DarkMode>
    </Grid>
  )
})

const SubPageServices = renderList(({ data }) => (
  <DarkMode>
    <Box
      bgcolor="secondary.main"
      color="primary.contrastText"
      p={4}
    >
      <Container maxWidth="md">
        <SubPageSubtitle>relatedServices</SubPageSubtitle>
        <Accessa.Lists.Archer
          lists={mapToListItem(data)}
          underline
        />
      </Container>
    </Box>
  </DarkMode>
))

const SubPageReferences = renderList(({ data }) => (
  <Box my={4}>
    <Container maxWidth="md">
      <SubPageSubtitle>relatedApplications</SubPageSubtitle>
      <Box mt={2}>
        <Accessa.Features.Field
          fill
          lg={4}
          lists={data.map((item) => ({
            title: get(item, 'hero.title'),
            description: get(item, 'hero.subtitle'),
            fluid: get(item, 'hero.image.fluid'),
            onClick: () =>
              navigate(get(item, 'relativePath', '/')),
            style: {
              height: 270,
              width: '100%',
            },
          }))}
        />
      </Box>
    </Container>
  </Box>
))

const SubPageResources = ({ blogs, downloads }) => {
  const { t } = useTranslation('titles')
  if (!hasLength(blogs) && !hasLength(downloads))
    return null

  const makeBody = (v) =>
    Array.isArray(v) ? v.map((c) => c.name).join(', ') : ''

  const BlogRenderer = renderList(({ data }) => (
    <Grid item xs={12} md={6}>
      <Tree
        lists={data.map((item) => ({
          title: get(item, 'title'),
          body: makeBody(get(item, 'categories')),
          onClick: () =>
            navigate(get(item, 'relativePath', '/')),
        }))}
      />
    </Grid>
  ))

  const DownloadRenderer = renderList(({ data }) => (
    <Grid item xs={12} md={6}>
      <Accessa.Lists.Downloads
        lists={data
          .map((item) => {
            const file = first(get(item, 'addYourPdFsHere'))
            if (!file) return {}

            return {
              title: get(item, 'title'),
              size: get(file, 'file.details.size'),
              path: get(file, 'file.url'),
              contentType: get(file, 'file.contentType'),
            }
          })
          .filter((item) => item.contentType)}
      />
    </Grid>
  ))

  return (
    <Box my={4}>
      <Container maxWidth="md">
        <SubPageSubtitle>
          {t('relatedResources')}
        </SubPageSubtitle>
        <Box mt={2}>
          <Grid container>
            <BlogRenderer data={blogs} />
            <DownloadRenderer data={downloads} />
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

SubPageResources.propTypes = {
  // eslint-disable-next-line
  blogs: PropTypes.array.isRequired,
  // eslint-disable-next-line
  downloads: PropTypes.array.isRequired,
}

export const SubPage = ({ contentfulSubPage }) => {
  const blogs = get(contentfulSubPage, 'relatedBlogs')
  const downloads = get(
    contentfulSubPage,
    'relatedDownloads'
  )

  return (
    <Box
      bgcolor="background.paper"
      position="relative"
      pb={hasLength(blogs) || hasLength(downloads) ? 4 : 0}
    >
      <Hero {...contentfulSubPage} />
      <Box my={8}>
        <Container maxWidth="md">
          <RichText
            json={get(contentfulSubPage, 'body.json')}
            locale={get(contentfulSubPage, 'node_locale')}
          />
        </Container>
      </Box>
      <Box py={4} bgcolor="grey.200">
        <Container maxWidth="md">
          <Grid container>
            <SubPageProducts
              data={get(contentfulSubPage, 'products')}
            />
            <SubPageMaterials
              data={get(
                contentfulSubPage,
                'commonMaterials'
              )}
            />
          </Grid>
        </Container>
      </Box>
      <SubPageServices
        data={get(contentfulSubPage, 'services')}
      />
      <SubPageReferences
        data={get(contentfulSubPage, 'relatedSubPages')}
      />
      <SubPageResources
        blogs={blogs}
        downloads={downloads}
      />
    </Box>
  )
}

SubPage.propTypes = {
  // eslint-disable-next-line
  contentfulSubPage: PropTypes.object.isRequired,
}

export const query = graphql`
  query getContentfulSubPageById(
    $contentful_id: String
    $node_locale: String
  ) {
    contentfulSubPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      hero {
        title
        subtitle
        image {
          fluid {
            src
          }
        }
      }
      seo {
        title
        description {
          description
        }
      }
      services
      products
      commonMaterials {
        name
      }
      body {
        json
      }
      relatedSubPages {
        relativePath
        hero {
          title
          image {
            fluid {
              src
            }
          }
        }
      }
      relatedBlogs {
        relativePath
        title
        categories {
          name
        }
      }
      relatedDownloads {
        title
        addYourPdFsHere {
          file {
            url
            contentType
            details {
              size
            }
          }
        }
      }
    }

    hreflang: allContentfulSubPage(
      filter: {
        contentful_id: { eq: $contentful_id }
        node_locale: { ne: $node_locale }
      }
    ) {
      nodes {
        node_locale
        relativePath
      }
    }
  }
`

export default withGlobals(SubPage)
